export const CONFIG = {
  logo: "/logo.png",
  site_title: "That One Tracker Management",
  Auth0Domain: "thatonetrackermanagement.us.auth0.com",
  Auth0ClientId: "i8QpQneHDHeVH2xqlV2mCPPyswutrLln",
  Auth0Audience: "https://thatonetrackermanagement-api.trackpac.io",
  API_URL: "https://management-api.thatonetracker.io/",
  mapboxAccessToken:
    "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg",
  mapStyle: "mapbox://styles/bfgneil/clkha3odl008i01qpeqws6d21",
  websiteURL: "https://app.thatonetracker.io",
  billingEnabled: false,
  deviceCost: 15,
  primaryColour: "#004AAD",
  secondaryColour: "#0d00ad",
};
